<template functionnal>
  <div class="template pb-4">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos accompagnements</h1>
          <div class="divider"></div>
          <div class="row">
            <div class="col-8">
              Les accompagnements sont ouverts à tous les habitants du 13 e sur rendez-vous. <br />
              <strong>Pour prendre rendez-vous :</strong><br />
              - 01.44.23.74.33<br />
              - 07.49.33.75.13<br />
              - j.femmesinitiatives@gmail.fr<br />
              - mediation.femmesinitiatives@gmail.com<br />
            </div>
            <div class="col-4">
              <img src="@/assets/images/11-accompagnements.jpg" alt="accompagnements" />
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>
            Médiation sociale et culturelle
          </h6>

          <div class="flex">
            La médiatrice socioculturelle vous accompagne et vous oriente pour vos démarches
            sociales, administratives et juridiques.<br />
            Elle vous aide pour vos démarches administratives en ligne (CAF, Sécurité Sociale, Pôle
            Emploi,…)<br /><br />

            <strong>Public</strong><br />
            <div>Public bénéficiaire de l'association et habitant Paris 13e</div>
            <strong>Pré-requis</strong><br />
            <div>Aucun</div>
            <strong>Objectif</strong><br />
            <div>
              Orienter et accompagner les personnes dans les démarches sociales, administratives et
              juridiques
            </div>
            <strong>Modalités d'inscription</strong><br />
            <div>Prendre RDV auprès de la médiatrice</div>
            <strong>Type d'accompagnement</strong><br />
            <div>Accompagnement indiviualisé</div>
            <strong>Tarif</strong><br />
            <div>Gratuit</div>

            <div class="mt-4">
              <a href="/mediation.pdf" download>Télécharger la plaquette</a>
            </div>
          </div>
        </div>

        <div class="col-6">
          <h6>
            Insertion professionnelle et employabilité
          </h6>

          <div class="accompagnement__right">
            La conseillère en insertion professionnelle vous reçoit en rendez-vous pour construire
            votre projet professionnel et mener vos recherches d’emploi, de formation ou/et de
            stage. <br /><br />

            <strong>Public</strong><br />
            <div>
              Public en recherche d'emploi ou en démarche d'insertion bénéficiaire de l'association
              ou habitant Paris 13e
            </div>
            <strong>Prérequis</strong><br />
            <div>Aucun</div>
            <strong>Objectifs</strong><br />
            <div>
              Aider à l'élaboration et au suivi d'un projet professionnel, aider à la recherche
              d'emploi, de formation et/ou de stage, accompagnement à l'auto-entreprenariat.
            </div>
            <strong>Modalités d'inscription</strong><br />
            <div>Prendre RDV auprès de la médiatrice</div>
            <strong>Type d'accompagnement</strong><br />
            <div>Accompagnement indiviualisé ou/et collectif</div>
            <strong>Tarif</strong><br />
            <div>Gratuit</div>

            <div class="mt-4">
              <a href="/insertion_socio_pro.pdf" download>Télécharger la plaquette</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoursDeFrancais"
};
</script>
